.navbar {
  background-color: #0c112c;
  padding: 1rem;
  color: white;
}
hr {
  border-top: 1px solid #adb5bd!important;
}
body {
  background-color: #white!important;
}
.form-container {
   max-width: 800px;
   margin: 0 auto;
   padding: 1rem;
   background-color: white;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-pdf__Page, .react-pdf__Document {
  background-color: rgb(82, 86, 89)!important;
  display: flex;
  justify-content: center;
  max-width: 100vw!important;
  min-width: auto!important;
}

/*Login *****/

.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.mh-200 {
  min-height: 200px!important;
}

.mh-250 {
  min-height: 250px!important;
}

.mh-400 {
  min-height: 400px!important;
}
